.choices,
.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item{
  font-size: inherit;  
}
.choices__list--dropdown, .choices__list[aria-expanded]{
  border-radius: 5px;
  min-width: 100%;
  width: auto;
}

.choices__list--single{
  padding: 0;  
  display: inline-flex;
}
.choices[data-type*=select-one]::after{
  display: none;
}
.choices__list--dropdown{
  color: #000000;
}
ng-choice-template{
  display: block;
}

@media (min-width: 640px){
  .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    //переопределил отступ, тк если ширина выпадающего списка маленькая, до у выпадающего списка появляется горизонтальный скролл
    padding-right: 15px;
  }
}
.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item{
  padding-left: 15px;
  padding-right: 15px;
}
.choices__item--selectable,
.choices__item--choice{
  white-space: nowrap;
}
.choices__item--selectable{
  text-overflow: ellipsis;
  overflow: hidden;
}